<template>
  <b-button
    variant="secondary"
    class="btn-sm btn-sm-block"
    :title="!disabledExport
      ? `Descargar ${modulo}`
      : `Marque los ${modulo} que desea descargar`"
    :disabled="disabledExport"
    @click.prevent="!disabledExport
      ? processExport()
      : ''"
  >
    <feather-icon
      icon="DownloadIcon"
    />
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BButton,
  },
  props: {
    modulo: {
      type: String,
      required: true,
    },
    disabledExport: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      idItems: [],
    }
  },
  methods: {
    processExport() {
      this.idItems = []
      this.items.forEach(item => {
        if (item.chkSelected) {
          this.idItems.push(item.id)
        }
      })
      const urlModulo = this.modulo.toLowerCase()
      const url = `${axios.defaults.baseURL}/${urlModulo}/export/${this.idItems}`
      window.location.href = url
    },
  },
}
</script>
